<template>
  <div>
    <div class="container text-center">
      <div class="row">
        <div class="col-sm-1">
          <img src="../assets/asura_m.png" class="rounded" width="60" height="120" alt="asura male">
        </div>
        <div class="my-auto col-sm-10">
          <h1 class="class-title text-center">{{ this.message }}</h1>
        </div>
        <div class="col-sm-1">
          <img src="../assets/asura_f.png" class="rounded" width="60" height="120" alt="asura female">
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <h2>Cartes d'équipements PvE</h2>
      <p>Aegwynn / Chevalier C. de Lion / Nébuleuse / Stephenv</p>
      <h2>Cartes soutien PvE</h2>
      <p>Meloetta / Summon - Mielette/ Dexter (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements Front de Gloire</h2>
      <p>Héron Bleu-de-Feu / Keukegen / Loras / Raton laveur de T</p>
      <h2>Cartes soutien Front de Gloire</h2>
      <p>Stephen / Mio la Farceuse / Dexter (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements PvP</h2>
      <p>Arcaria / Mio la Farceuse / Chevalier C. de Lion / Assassin Chat Noir </p>
      <h2>Cartes soutien PvP</h2>
      <p>Gobelin Satanique / Stephen / Dexter (3ème disposition de soutien / ligne +15 orange)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Asura',
  data() {
    return {
      products: [],
      message: "Tuto Asura",
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
