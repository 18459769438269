<template>
  <div class="">
    <div class="container text-center">
      <div class="row">
        <div class="col-sm-1">
          <img src="../assets/warrior_m.png" class="rounded" width="55" height="120" alt="warrior male">
        </div>
        <div class="my-auto col-sm-10">
          <h1 class="class-title text-center">{{ this.message }}</h1>
        </div>
        <div class="col-sm-1">
          <img src="../assets/warrior_f.png" class="rounded" width="55" height="120" alt="warrior female">
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <h2>Cartes d'équipements PvE</h2>
      <p>Mio la Farceuse / Chevalier C. de Lion / Assasin Chat Noir / Nébuleuse</p>
      <h2>Cartes soutien PvE</h2>
      <p>Corbeille de G. / Stephen / Dexter (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements Front de Gloire</h2>
      <p>Héron Bleu-de-Feu / Keukegen / Loras / Raton laveur de T</p>
      <h2>Cartes soutien Front de Gloire</h2>
      <p>Mio la Farceuse / Stephen / Dexter (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements PvP</h2>
      <p>Dracchen / Granny / Ogre Lehr / Reverseur</p>
      <h2>Cartes soutien PvP</h2>
      <p>Mio la Farceuse / Corbeille de G. / Arcaria (3ème disposition de soutien / ligne +15 orange)</p>
      <h3>Information Complémentaire</h3>
      <p> Le PvE est constituer de sorte a ce qu'on puisse faire plus de dégats sur la portion 100% / 50% des PV des Boss
        (augmentant la durée des la prise d'aggro avec les compétences au début des affrontements)</p>
      <p> Pour le PvP, Renverseur est la seul carte actuellement qui donne de la Def % sans présence de Blocages (qui
        réduit les dégats de renvois) en plus de fournir de la résistance au dégâts de cible sous débuff (étourdissement
        ou effet d'état le gurerrier et son équipe) la présence des 4 Reverseur augmenterais la Def de 84% (21% par
        Renverseur) ce
        qui pourrait permettre de toucher surement les 200 000 de Défense ainsi que 32% de PV (Utile sur un aspect durée de
        vie)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paladin',
  data() {
    return {
      products: [],
      message: "Tuto Paladin",
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sedgwick+Ave&display=swap');

h1 {
  font-family: 'Sedgwick Ave', cursive;
} */
</style>
