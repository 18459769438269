<template>
  <div>
    <div class="container text-center">
      <div class="row">
        <div class="col-sm-1">
          <img src="../assets/pyro_m.png" class="rounded" width="60" height="120" alt="pyro male">
        </div>
        <div class="my-auto col-sm-10">
          <h1 class="class-title text-center">{{ this.message }}</h1>
        </div>
        <div class="col-sm-1">
          <img src="../assets/pyro_f.png" class="rounded" width="60" height="120" alt="pyro female">
        </div>
      </div>
      <div class="container mt-5"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pyromencer',
  data() {
    return {
      products: [],
      message: "Tuto Pyromencer",
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
