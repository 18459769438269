<template>
  <div>
    <div class="container text-center">
      <div class="row">
        <div class="col-sm-1">
          <img src="../assets/spirit_m.png" class="rounded" width="65" height="120" alt="spirit male">
        </div>
        <div class="my-auto col-sm-10">
          <h1 class="class-title text-center">{{ this.message }}</h1>
        </div>
        <div class="col-sm-1">
          <img src="../assets/spirit_f.png" class="rounded" width="65" height="120" alt="spirit female">
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <h2>Cartes d'équipements PvE</h2>
      <p>Chevalier C. de Lion / Aegwynn / Nébuleuse / Stephen</p>
      <h2>Cartes soutien PvE</h2>
      <p>Meloetta - Chaton Magique / Dexter / Ass' Chat Noir (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements Front de Gloire</h2>
      <p>Héron Bleu-de-Feu / Keukegen / Loras / Raton laveur de T</p>
      <h2>Cartes soutien Front de Gloire</h2>
      <p>Dexter / Garde en Métal / Chevalier C. de Lion (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements PvP</h2>
      <p>Chevalier C. de Lion / Aegwynn / Arcaria / Ass' Chat Noir</p>
      <h2>Cartes soutien PvP</h2>
      <p>Stephen / Dexter / Corbeille de Givre (3ème disposition de soutien / ligne +15 orange)</p>
      <h3>Information Complémentaire</h3>
      <p>Possibilité de gagner 9 % d'Ignore Def (en reforge) et 5 % de DGT au Boss (en redistribution) sur les gants</p>
      <p>Si Forêt Verte (RVIII) n'est pas joué comme compétence de dégâts, il faudra l'échanger en soutien par Poisson
        austral (compétences qui marque les ennemis pour augmenter les dégats des alliées)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spirit-master',
  data() {
    return {
      products: [],
      message: "Tuto Spirit Master",
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
