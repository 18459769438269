<template>
  <div>
    <div class="container text-center">
      <div class="row">
        <div class="col-sm-1">
          <img src="../assets/priest_m.png" class="rounded" width="60" height="120" alt="priest male">
        </div>
        <div class="my-auto col-sm-10">
          <h1 class="class-title text-center">{{ this.message }}</h1>
        </div>
        <div class="col-sm-1">
          <img src="../assets/priest_f.png" class="rounded" width="60" height="120" alt="priest female">
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <h2>Cartes d'équipements PvE</h2>
      <p>Mio la Farceuse / Raton l. de B. / Etoile Ama' / Melissa</p>
      <h2>Cartes soutien PvE</h2>
      <p>Chaton Magique / Astana / Alpha - QingQing - Smookie (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements Front de Gloire</h2>
      <p>Héron Bleu-de-Feu / Keukegen / Mina / Raton laveur de T</p>
      <h2>Cartes soutien Front de Gloire</h2>
      <p>Melissa / Raton l. de B. / Etoile Amaterasu (3ème disposition de soutien / ligne +15 orange)</p>
      <h2>Cartes d'équipements PvP</h2>
      <p>Astana / Raton l. de B. / Etoile Amaterasu / Melissa</p>
      <h2>Cartes soutien PvP</h2>
      <p>Renverseur - Chaton / Dela / Alpha - QingQing - Smookie (3ème disposition de soutien / ligne +15 orange)</p>
      <h3>Information Complémentaire</h3>
      <p>Dans le cadre de l'augmentation des soins, les statistiques donne 23 % de soin supplémentaire si les Mio
        fournissent chacunes + 450 d'atk</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Priest',
  data() {
    return {
      products: [],
      message: "Tuto Priest",
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
